import React from 'react';
import PropTypes from 'prop-types';
import { Link, graphql } from 'gatsby';
import Img from 'gatsby-image';
import Layout from '@components/layout';
import SEO from '@components/seo';
import Search from '@components/searchWrapper';
import IndexPageStyles from '@styles/indexPage';

export default function IndexPage({ data: { categories, home } }) {
  let i = 0;
  return (
    <Layout page="/">
      <SEO title="Home" keywords={home.tags} />
      <IndexPageStyles>
        <div className="hero-wrapper">
          <div className="inner">
            <div className="search-wrapper">
              <div className="search-box">
                <h1 className="hidden-visually">Dutch Rentalz</h1>
                <h2>
                  Your small town, service-oriented equipment and tool rental
                  company.
                </h2>

                <div className="search">
                  <p>Search our available inventory</p>
                  <Search page="/" />
                  {/* <div className="browse-all">
                    <strong>Browse All:</strong>
                    <Link to="/rentals">Rentals</Link>
                    <span className="separator">|</span>
                    <Link to="/for-sale">For Sale</Link>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="featured-categories-wrapper">
              <div className="featured-categories">
                {/* TODO: replace with featured categories */}
                {home.data.related_items.map(({ related_item }) => {
                  const { document } = related_item;
                  const { data } = document;
                  if (i >= 6) return;
                  i += 1;
                  return (
                    <div
                      className="featured-category-wrapper"
                      key={data?.name || data?.title?.text}
                    >
                      <Link
                        to={
                          document.type === `categories`
                            ? `/${document.uid}/equipment`
                            : `${data.category.uid}/${
                                document.type === `for_sale`
                                  ? `for-sale`
                                  : document.type
                              }/${document.uid}`
                        }
                        className="featured-category"
                      >
                        <p>{data?.name || data?.title?.text}</p>
                        {data?.image?.fluid && (
                          <div className="image-wrapper">
                            <Img
                              fluid={data.image.fluid}
                              alt={data?.name || data?.title?.text}
                            />
                          </div>
                        )}
                      </Link>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="about-items-wrapper">
          <div className="inner">
            <div className="about-items">
              {home.data.about.map(node => (
                <div className="about-item-wrapper" key={node.title.text}>
                  <div className="about-item">
                    {node.image?.fluid && (
                      <div className="image-wrapper">
                        <Img
                          fluid={node.image.fluid}
                          alt={node.image?.alt || node.title?.text}
                        />
                      </div>
                    )}
                    {node.title?.html && (
                      <p className="about-title">{node.title?.text}</p>
                    )}
                    {node.copy?.html && (
                      <div
                        dangerouslySetInnerHTML={{ __html: node.copy.html }}
                      />
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="categories-wrapper">
          <section className="inner">
            <header>
              <h2>Equipment</h2>
            </header>
            <div className="categories">
              {categories.edges.map(
                ({ node }) =>
                  node.data.image?.fluid && (
                    <Link
                      to={`/${node.uid}/equipment`}
                      className="category"
                      key={node.data.name}
                    >
                      <p>{node.data.name}</p>
                      <div className="image-wrapper">
                        <Img
                          fluid={node.data.image.fluid}
                          alt={node.data.name}
                        />
                      </div>
                    </Link>
                  )
              )}
            </div>
            {/* <div className="categories-link-group">
              <Link to="/">Rentals</Link>
              <Link to="/">For Sale</Link>
            </div> */}
          </section>
        </div>

        <div className="contact-us-wrapper">
          <div className="contact-us">
            <p>Contact Us Today To Get Your Rental Started!</p>
            <Link to="/contact-us">Contact Us</Link>
          </div>
        </div>
      </IndexPageStyles>
    </Layout>
  );
}

IndexPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export const query = graphql`
  query homeQuery {
    home: prismicHomePage {
      id
      tags
      uid
      data {
        about {
          title {
            text
            html
          }
          image {
            alt
            fluid(maxWidth: 200, maxHeight: 200) {
              ...GatsbyPrismicImageFluid
            }
          }
          copy {
            html
          }
        }
        banner_title {
          text
        }
        banner_copy {
          text
        }
        banner_image {
          url
        }
        related_items {
          related_item {
            id
            slug
            document {
              ... on PrismicAttachments {
                id
                type
                uid
                data {
                  category {
                    uid
                  }
                  image {
                    url
                    fluid(maxWidth: 200, maxHeight: 200) {
                      ...GatsbyPrismicImageFluid
                    }
                  }
                  title {
                    text
                    html
                  }
                }
              }
              ... on PrismicCategories {
                id
                type
                uid
                data {
                  image {
                    url
                    fluid(maxWidth: 200, maxHeight: 200) {
                      ...GatsbyPrismicImageFluid
                    }
                  }
                  name
                }
              }
              ... on PrismicEquipment {
                id
                type
                uid
                data {
                  category {
                    uid
                  }
                  image {
                    url
                    fluid(maxWidth: 200, maxHeight: 200) {
                      ...GatsbyPrismicImageFluid
                    }
                  }
                  title {
                    text
                    html
                  }
                }
              }
              ... on PrismicForSale {
                id
                type
                uid
                data {
                  category {
                    uid
                  }
                  image {
                    url
                    fluid(maxWidth: 200, maxHeight: 200) {
                      ...GatsbyPrismicImageFluid
                    }
                  }
                  title {
                    text
                    html
                  }
                }
              }
            }
          }
        }
      }
    }
    categories: allPrismicCategories {
      edges {
        node {
          id
          uid
          data {
            image {
              url
              fluid(maxWidth: 265, maxHeight: 265) {
                ...GatsbyPrismicImageFluid
              }
            }
            name
          }
        }
      }
    }
  }
`;
