import styled from 'styled-components';

const IndexPageStyles = styled.div`
  padding: 0;

  .hero-wrapper {
    padding: 0;
    background-image: url('../images/topography.svg');
    position: relative;
    overflow: hidden;
    background-position: center;
    background-size: 50%;
  }

  .hero-wrapper .inner {
    display: flex;
    z-index: 1;
    padding: 0;
    position: relative;
    flex-direction: column;

    @media screen and (min-width: 1024px) {
      align-items: stretch;
      flex-direction: row;
    }
  }

  .search-wrapper {
    display: flex;
    align-items: center;
    color: var(--white);
    width: 100%;

    @media screen and (min-width: 768px) and (max-width: 1023px) {
      background: var(--primary-50);
      box-shadow: 0.1rem 0.1rem 0.1rem hsla(0, 0%, 0%, 0.5);
    }

    @media screen and (min-width: 1024px) {
      width: 33.333%;
      position: absolute;
      top: 0;
      left: 2rem;
      bottom: 0;
      z-index: 10;
    }

    .search-box {
      padding: 3rem;
      background: var(--primary-50);
      border-radius: 0.3rem;
      box-shadow: 0.1rem 0.1rem 0.1rem hsla(0, 0%, 0%, 0.5);
      position: relative;

      @media screen and (min-width: 768px) and (max-width: 1023px) {
        box-shadow: none;
        margin: auto;
        max-width: 76.8rem;
      }
    }

    h2 {
      color: var(--white);
      font-size: 3.2rem;
      font-style: italic;
      margin-bottom: 4rem;
    }

    .search > p {
      font-size: 2.4rem;
      margin-bottom: 1rem;

      @media screen and (min-width: 768px) {
        font-size: 3.2rem;
      }
    }

    .search-bar {
      margin: 0 0 2rem;
    }

    .search-bar .inner {
      padding: 0;
    }

    form {
      border-radius: 0.2rem;
      overflow: hidden;
    }

    #searchInputWrapper {
      width: calc(100% - 6rem);

      @media screen and (min-width: 768px) {
        width: calc(100% - 10rem);
      }

      input {
        border-radius: 0;
      }
    }

    button[type='submit'] {
      background: var(--accent-40);
      border-radius: 0;
      width: 6rem;

      @media screen and (min-width: 768px) {
        width: 10rem;
      }

      &:hover {
        background: var(--accent-50);
      }
    }

    .browse-all {
      font-size: var(--fs-base);

      strong {
        margin-right: 0.5rem;
      }

      a {
        color: var(--white);
        display: inline-block;
        padding: 0 1rem;
      }

      .separator
        background: var(--white);
      }
    }
  }

  .featured-categories-wrapper {
    background: var(--offwhite);
    padding: 2rem 0;
    position: relative;
    width: 100%;

    @media screen and (min-width: 1024px) {
      margin-left: 25%;
      padding: 2rem 0 2rem 20rem;
      width: 75%;
    }

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      right: -100%;
      bottom: 0;
      content: '';
      background: var(--offwhite);
      z-index: -1;
    }
  }

  .featured-categories {
    display: flex;
    flex-wrap: wrap;
  }

  .featured-category-wrapper {
    width: 50%;
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 1rem;

    @media screen and (min-width: 600px) {
      padding: 2rem;
      width: 33.333%;
    }

    @media screen and (min-width: 768px) {
      width: 33.333%;
    }

    .featured-category {
      background: var(--white);
      border-radius: .3rem;
      border: .1rem solid var(--grayscale-30);
      box-shadow: .2rem .2rem .2rem .2rem hsla(0, 0%, 0%, .025);
      height: auto;
      text-decoration: none;
      width: 100%;
    }

    .featured-category p {
      color: var(--text-color);
      padding: 0 1rem;
      text-align: center;
      width: 100%;
      font-family: var(--font-serif);
    }

    .image-wrapper {
      align-items: center;
      background: var(--white);
      display: flex;
      height: auto;
      justify-content: center;
      overflow: hidden;
      padding: 2rem;
      width: 100%;
    }

    .gatsby-image-wrapper {
      max-height: 10rem;
      max-width: 10rem;
      min-height: 10rem;
      min-width: 10rem;

      @media screen and (min-width: 768px) {
        max-height: 12rem;
        max-width: 12rem;
        min-height: 12rem;
        min-width: 12rem;
      }
    }

    img {
      background: var(--white);
      max-height: 10rem;
      max-width: 10rem;

      @media screen and (min-width: 768px) {
        max-height: 12rem;
        max-width: 12rem;
      }
    }
  }

  .about-items {
    display: flex;
    flex-wrap: wrap;
    padding: 2rem 0;

    .about-item-wrapper {
      align-items: flex-start;
      display: flex;
      justify-content: center;
      padding: 2rem;
      text-align: center;
      width: 100%;

      @media screen and (min-width: 768px) {
        width: 50%;
      }

      @media screen and (min-width: 768px) {
        width: 33.333%;
      }

      .about-item {
        max-width: 24rem;
      }

      .about-title {
        font-size: var(--fs-xl);
        font-weight: bold;
        margin: 0;
      }

      > div p {
        margin: 0;
      }

      .image-wrapper {
        max-height: 20rem;
        max-width: 20rem;
        margin: auto;
      }

      img {
        max-height: 20rem;
        max-width: 20rem;
      }
    }
  }

  .categories-wrapper {
    background: var(--offwhite);
    background: linear-gradient(180deg, var(--offwhite) 45%, var(--white) 45%, var(--white) 100%);

    header {
      margin-bottom: 2rem;
      padding: 4rem 0;

      @media screen and (min-width: 768px) {
        padding: 4rem 20rem;
      }
    }

    h2 {
      font-size: var(--fs-3xl);
      position: relative;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: calc(100% + 1rem);
        width: 6rem;
        height: 0.6rem;
        background: var(--primary-50);
      }
    }
  }

  .categories-wrapper .categories {
    display: flex;
    flex-wrap: wrap;
    padding: 0 0 4rem;

    @media screen and (min-width: 768px) {
      padding: 0 4rem 4rem;
    }

    .category {
      --category-width: 50%;
      background: var(--white);
      border: .1rem solid var(--grayscale-30);
      color: var(--white):
      display: flex;
      flex-direction: column;
      padding: 1rem 2rem 2rem;
      text-decoration: none;
      width: var(--category-width);

      @media screen and (min-width: 768px) {
        --category-width: 33.333%;
      }

      @media screen and (min-width: 1024px) {
        --category-width: 20%;
      }

      @media screen and (max-width: 767px) {
        &:nth-child(even) {
          border-left: none;
        }

        &:not(:first-child):not(:nth-child(2)) {
          border-top: none;
        }
      }

      @media screen and (min-width: 768px) and (max-width: 1023px) {
        &:not(:nth-child(3n+3)):not(:last-child) {
          border-right: none;
        }

        &:not(:first-child):not(:nth-child(2)):not(:nth-child(3)) {
          border-top: none;
        }

        &:last-child {
          width: calc(var(--category-width) + 0.1rem);
        }
      }

      @media screen and (min-width: 1024px) {
        &:not(:nth-child(5n)):not(:last-child) {
          border-right: none;
        }

        &:nth-child(1n + 6) {
          border-top: none;
        }

        &:last-child {
          width: calc(var(--category-width) + 0.1rem);
        }
      }

      p {
        /* font-weight: 600; */
        font-family: var(--font-serif);
        text-align: center;
      }

      .image-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: auto;
      }

      .gatsby-image-wrapper {
        max-height: 10rem;
        max-width: 10rem;
        min-height: 10rem;
        min-width: 10rem;

        @media screen and (min-width: 768px) {
          max-height: 15rem;
          max-width: 15rem;
          min-height: 15rem;
          min-width: 15rem;
        }
      }

      img {
        height: 15rem;
        width: 15rem;
      }
    }
  }

  .categories-link-group {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 2rem 2rem 6rem;

    @media screen and (min-width: 768px) {
      flex-direction: row;
    }

    a {
      background: var(--accent-50);
      border-radius: 0.3rem;
      color: var(--white);
      margin: 0 1rem 2rem;
      padding: 1.2rem 7.5rem;
      text-align: center;
      text-decoration: none;
      width: 100%;

      @media screen and (min-width: 768px) {
        margin: 0 1rem;
        width: auto;
      }
    }
  }

  .contact-us-wrapper {
    padding: 0;
  }

  .contact-us {
    background: var(--grayscale-30);
    color: var(--text-color);
    padding: 4rem;
    text-align: center;

    p {
      font-size: var(--fs-2xl);
      text-transform: uppercase;
      margin: 0;
      font-weight: 600;
    }

    a {
      color: var(--white);
      background: var(--primary-50);
      border-radius: 0.3rem;
      padding: 1rem 2rem;
      font-size: var(--fs-lg);
      text-decoration: none;
      margin-top: 2rem;
      display: inline-block;
    }
  }
`;

export default IndexPageStyles;
